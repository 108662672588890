import React from "react"
import Reviews from "../components/Reviews"
import HowItWorks from "../components/HowItWorks"
import { StaticImage } from 'gatsby-plugin-image'

import { ImDownload, ImLaptop, ImTruck } from "react-icons/im"
import PrivateCloudVideo from "../images/privateCloud.mp4"
import Features from "../components/Features"

const customers = [ // TODO: Use Gatsby or strapi to host images
    "https://antsle.com/wp-content/uploads/2018/10/dell.png",
    "https://antsle.com/wp-content/uploads/2018/10/symantec.png",
    "https://antsle.com/wp-content/uploads/2018/10/usnavy.png",
    "https://antsle.com/wp-content/uploads/2018/10/fujitsu.png",
    "https://antsle.com/wp-content/uploads/2018/10/johnsonjohnson.png",
    "https://antsle.com/wp-content/uploads/2018/10/pennstate-1.png",
    "https://antsle.com/wp-content/uploads/2019/04/att.png",
]

export default function cloneOfPrivateCloudServerPage() {
    return (
        <>
            <section className="container flex flex-col py-10 space-y-5 md:flex-row md:space-y-0 md:py-20 md:space-x-10">
                <div className="space-y-5 ">
                    <h1 className="text-2xl font-bold text-gray-700 md:text-4xl">
                        Private Cloud Made Easy.
                    </h1>
                    <p className="md:text-lg 2xl:text-xl">
                        Built to optimize your virtualized cloud: Get set up in{" "}
                        <span className="text-red">5min</span>, launch any VPS
                        in <span className="text-red">10s</span>, and save{" "}
                        <span className="text-red">75%</span>+ over VMware.
                    </p>
                    <p>
                        Cutting edge rack mount server from the virtualization experts.
                    </p><p>
                        Built in California -- German Engineered.
                    </p><p>
                        100% turnkey platform. It comes ready-to-go with edgeLinux and antMan preinstalled.
                        Create instances via 1-click from our list of OS templates.
                    </p>
                    <a
                        href="https://antsle.com/shop-antsle-two-rackmount-servers/#0"
                        className="btn-red"
                    >
                        Configure Yours!
                    </a>
                    <StaticImage
                       alt="1U Rackmount Server Antsle Two with AMD EPYC Milan"
                       src="../images/AntsleTwoSmall.png"
                    />
                </div>
                <video
                    className="h-auto max-w-[700px]"
                    loop
                    controls={false}
                    autoPlay
                    muted
                >
                    <source src={PrivateCloudVideo} type="video/mp4" />
                </video>
            </section>
            <section className="container py-10 md:py-20">
                <p className="mb-10 text-2xl text-center">
                    A few of our customers:
                </p>
                <div className="grid justify-center grid-cols-3 md:flex">
                    {customers.map((img, i) => (
                        <img
                            src={img}
                            key={i}
                            alt={`partner ${i}`}
                            className="w-auto max-h-[150px]"
                        />
                    ))}
                </div>
            </section>
            <Reviews />
            <section className="container py-10 md:py-20">
                <h1 className="text-5xl font-bold text-center">
                    Meet <span className="text-red">antsle.</span>
                </h1>
                <p className="mx-auto text-lg text-center md:w-1/2 md:text-2xl">
                    One secure platform to build, deploy & scale. <br />
                    It's the cloud made for classrooms.
                </p>
                <div className="flex flex-col pt-20 space-y-5 text-lg md:space-x-20 md:flex-row md:space-y-0">
                    <div className="flex flex-col items-start space-y-3">
                        <h2 className="text-2xl font-bold text-gray-700">
                            Get Started in Minutes.
                        </h2>
                        <p>
                            No bloated dashboards or ﻿complicated﻿ setup
                            processes: Get set up in 5 minutes! Deploy both
                            VM-based (KVM) or Container-based (LXC) Virtual
                            Servers in less than 10 seconds.
                        </p>
                        <p>
                            Built on a cutting-edge Linux hypervisor with one of
                            the simplest dashboards you will ever use: edgeLinux
                            gives you instant snapshots & rollbacks, full
                            templating, and more to optimize the DevOps
                            workflow.
                        </p>
                        <img
                            className="md:max-w-[500px] mx-auto pt-10 h-auto"
                            src="https://go.antsle.com/wp-content/uploads/2022/01/AntsleTwo01.png"
                            alt=""
                        />
                    </div>
                    <div className="flex flex-col space-y-3">
                        <img
                            className="md:max-w-[500px] mx-auto pb-10 h-auto"
                            src="https://go.antsle.com/wp-content/uploads/2019/09/laptop-and-phone-isolated-4.png"
                            alt=""
                        />
                        <h2 className="text-2xl font-bold text-gray-700">
                            Compact & 100% Silent.
                        </h2>
                        <p>
                            With popular edgeLinux preinstalled, every Antsle is
                            ready out of the box: With up to 256GB RAM and 18TB
                            disk space all Antsles are custom-built and can be
                            deployed anywhere thanks to the compact case &
                            fanless design.{" "}
                        </p>
                        <p>
                            With fault-tolerance technology built in, you have
                            full control over your hardware and data. All parts
                            were hand-selected for performance: ECC RAM, SSDs
                            throughout & data 100% mirrored.
                        </p>
                        <a
                            href="https://antsle.com/shop-antsle-two-rackmount-servers/#0"
                            className="self-center btn-red"
                        >
                            Configure & Buy
                        </a>
                    </div>
                </div>
            </section>
            <HowItWorks />
            <section className="py-10 bg-gray-100 md:py-20">
                <div className="container">
                    <h1 className="mb-2 text-2xl font-bold text-center md:text-5xl">
                        Migration is Easy.
                    </h1>
                    <p className="text-2xl text-center text-gray-600">
                        Coming from VMWare, AWS etc? We got you covered.
                    </p>
                    <div className="flex flex-col justify-between pt-10 space-y-10 md:space-y-0 md:flex-row">
                        <div className="flex flex-col items-center space-y-5">
                            <ImDownload className="text-6xl text-red" />
                            <p className="text-xl font-bold text-gray-700">
                                Import your Images
                            </p>
                            <p className="mx-auto text-lg text-center md:w-2/3">
                                Migrate from your current solution like VMWare
                                by simply importing your existing images via
                                drag & drop.
                            </p>
                        </div>
                        <div className="flex flex-col items-center space-y-5">
                            <ImLaptop className="text-6xl text-red" />
                            <p className="text-xl font-bold text-gray-700">
                                Run any OS
                            </p>
                            <p className="mx-auto text-lg text-center md:w-2/3 ">
                                Antsle was developed for Linux but lets you run
                                any OS: Windows Server, Linux, *BSD...
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container py-10 md:py-20">
                <h1 className="text-2xl text-center">
                    Free Shipping & 30-Day Money Back Guarantee.
                </h1>
                <Features />
            </section>
            <section className="py-10 bg-gray-100 md:py-20">
                <div className="container flex flex-col items-center justify-center space-y-3">
                    <ImTruck className="text-6xl text-red" />
                    <h1 className="text-4xl font-bold text-center">
                        Love It - or your money back!
                    </h1>
                    <p className="mx-auto text-xl text-center md:w-1/2">
                        Test an Antsle Private Cloud Server in your own
                        environment. We'll take any Antsle back within 30 days,
                        no questions asked.
                    </p>
                </div>
            </section>
        </>
    )
}
